<template>
  <main class="page">
    <ScannerQR v-if="isScannerQR" @onClose="showScanner"></ScannerQR>
    <h1 class="page__mobile-title">Заявки</h1>
    <RequestsListActions @change="actionsChange" @reset="actionsReset" @scannerChange="showScanner" />
    <Table
      :columns="columns"
      :rows="requests.data || []"
      listClass="request-list"
      :total="requests.paginatorInfo ? requests.paginatorInfo.total : 0"
      :page="page"
      pagination
      :initialSortBy="vgtFormattedOrdering"
      @paginate="changePage"
      @sort="changeSort"
    >
      <div slot="empty" class="td-container">
        <span class="table-span">
          <LoadingIndicator v-if="loading" />
          <template v-else>Данных нет</template>
        </span>
      </div>
      <template slot="table-row" slot-scope="{ row, column }">
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'id'">
          <span class="table-span">
            {{ row.id }}
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'created_at'">
          <span class="date">
            <span class="date__main">{{ row.created_at | formatDate }}</span>
            <span class="date__sub">{{ row.created_at | formatTime }}</span>
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'provider'">
          <span class="table-span">
            {{ row.provider | getCompany }}
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'supplier'">
          <span class="table-span">
            {{ row.supplier | getFIO }}
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'delivery'">
          <span class="table-span">
            <template v-if="row.delivery && row.delivery.type && row.delivery.type.code === 2">
              {{ row.delivery | getCompany }}
            </template>
            <template v-else-if="row.delivery && row.delivery.type">
              Доставка осуществляется своими силами
            </template>
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'status'">
          <span :class="getStatus(row)">
            {{ row.status.name }}
          </span>
        </router-link>
        <div class="td-container" v-if="column.field === 'pass'">
          <button class="btn btn--small">Создать пропуск</button>
        </div>
        <div class="td-container" v-if="column.field === 'actions'">
          <div class="table-actions">
            <router-link :to="getRoute(row.id)" class="table-action">
              <EditIcon v-if="row.can_edit || row.can_edit_delivery" />
              <EyeOnIcon v-else />
            </router-link>
          </div>
        </div>
      </template>
      <div slot="table-m-item" slot-scope="{ row }" class="card">
        <div class="card__header">
          <span class="card__id">{{ row.id }}</span>
          <span :class="getStatus(row)">{{ row.status.name }}</span>
        </div>
        <div class="card__body">
          <div class="card__item" v-if="canShowColumn('provider')">
            <span class="card__item-title">Поставщик</span>
            <span class="card__item-value">
              {{ row.provider | getCompany }}
            </span>
          </div>
          <div class="card__item" v-if="canShowColumn('supplier')">
            <span class="card__item-title">Куратор</span>
            <span class="card__item-value">
              {{ row.supplier | getFIO }}
            </span>
          </div>
          <div class="card__item" v-if="canShowColumn('delivery')">
            <span class="card__item-title">Подрядчик</span>
            <span class="card__item-value">
              <template v-if="row.delivery && row.delivery.type && row.delivery.type.code === 2">
                {{ row.delivery | getCompany }}
              </template>
              <template v-else-if="row.delivery && row.delivery.type">
                Доставка осуществляется своими силами
              </template>
            </span>
          </div>
        </div>
        <div class="card__footer">
          <router-link
            :to="getRoute(row.id)"
            class="btn-gray btn-gray--outline btn-gray--md btn-gray--icon-left"
          >
            <EditIcon v-if="row.can_edit || row.can_edit_delivery" />
            <EyeOnIcon v-else />
            <span v-if="row.can_edit || row.can_edit_delivery">Редактировать</span>
            <span v-else>Просмотр</span>
          </router-link>
          <button class="btn btn--md" v-if="canShowColumn('pass')">Создать пропуск</button>
        </div>
      </div>
    </Table>
  </main>
</template>

<script>
import RequestsListActions from "./components/actions";
import Table from "@/components/Table";
import EditIcon from "@/components/svg/EditIcon";
import EyeOnIcon from "@/components/svg/EyeOnIcon";
import moment from "moment";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ScannerQR from "@/components/qrscanner";
export default {
  name: "RequestsList",
  data() {
    return {
      isScannerQR: false,
      loading: false,
      loadingCreateAccess: null,
      page: 1,
      ordering: [
        {
          orderColumn: "created_at",
          orderBy: "DESC",
        },
      ],
      onlyMine: false,
      providers: null,
      suppliers: null,
      contractors: null,
      start_date: null,
      end_date: null,
      status: null,
    };
  },
  computed: {
    vgtFormattedOrdering() {
      return this.ordering.map((o) => ({
        field: o.orderColumn,
        type: o.orderBy.toLowerCase(),
      }));
    },
    requests() {
      return this.$store.state.requests;
    },
    isProvider() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "provider";
        }).length;
      }
      return false;
    },
    isPassportOffice() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "passport_office" || r.name === "passport_office_leader";
        }).length;
      }
      return false;
    },
    me() {
      return this.$store.state.me;
    },
    columns() {
      return [
        {
          label: "ID",
          field: "id",
          sortable: true,
          show: this.canShowColumn("id"),
        },
        {
          label: "Дата",
          field: "created_at",
          sortable: true,
          show: this.canShowColumn("created_at"),
        },
        {
          label: "Поставщик",
          field: "provider",
          sortable: true,
          show: this.canShowColumn("provider"),
        },
        {
          label: "Подрядчик",
          field: "delivery",
          sortable: true,
          show: this.canShowColumn("delivery"),
        },
        {
          label: "Куратор",
          field: "supplier",
          sortable: true,
          show: this.canShowColumn("supplier"),
        },
        {
          label: "Статус",
          field: "status",
          sortable: true,
          show: this.canShowColumn("status"),
        },
        {
          field: "pass",
          sortable: false,
          show: this.canShowColumn("pass"),
        },
        {
          field: "actions",
          sortable: false,
          show: this.canShowColumn("actions"),
        },
      ].filter((c) => c.show);
    },
  },
  created() {
    let storageVariables = localStorage.getItem("requests_list." + this.me.id);
    if (storageVariables) {
      storageVariables = JSON.parse(storageVariables);
      Object.keys(storageVariables).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(this, key)) {
          this[key] = storageVariables[key];
        }
      });
    }
    this.getRequests();
  },
  methods: {
    getRoute(id) {
      if (this.isProvider) {
        return { name: "RequestCreateContinue", params: { id: id } };
      }
      return { name: "Request", params: { id: id } };
    },
    canShowColumn(columnName) {
      switch (columnName) {
        case "id":
        case "created_at":
        case "provider":
        case "delivery":
        case "status":
        case "actions":
          return true;
        case "supplier":
          return this.isProvider;
        case "pass":
          return false;
      }
    },
    getStatus(row) {
      let string = "status";
      switch (row.status.code) {
        case 1:
          break;
        case 3:
        case 4:
        case 5:
          string += " status--red";
          break;
        case 2:
        case 6:
        case 100:
          string += " status--orange";
          break;
        case 7:
          string += " status--green";
          break;
      }
      return string;
    },
    getRequests() {
      if (!this.loading) {
        this.loading = true;
        this.$store.state.requests = {};
        let variables = this.clean({
          contractors: this.contractors,
          ordering: this.ordering,
          suppliers: this.suppliers,
          providers: this.providers,
          status: this.status,
          onlyMine: this.onlyMine,
          start_date: this.start_date,
          end_date: this.end_date,
          page: this.page,
          first: 11,
        });
        localStorage.setItem("requests_list." + this.me.id, JSON.stringify(variables));
        this.$store
          .dispatch("GET_REQUESTS", {
            context: this,
            variables: variables,
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    clean(obj) {
      for (let propName in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, propName) &&
          (obj[propName] === null || obj[propName] === undefined)
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    actionsChange(event) {
      this.page = 1;
      if (event.name === "type") {
        this.onlyMine = event.value && event.value.id === 1;
      }
      switch (event.name) {
        case "providers":
        case "suppliers":
        case "contractors":
        case "status":
          this.changeField(event);
          break;
        case "date":
          if (event.value) {
            const format = "YYYY-MM-DD HH:mm:ss";
            this.start_date = moment(event.value.start).format(format);
            this.end_date = moment(event.value.end).format(format);
          } else {
            this.start_date = null;
            this.end_date = null;
          }
      }
      this.getRequests();
    },
    changeField(event) {
      if (event.value.length) {
        this[event.name] = event.value.map((p) => p.id);
      } else {
        this[event.name] = null;
      }
    },
    actionsReset() {
      this.page = 1;
      this.providers = null;
      this.suppliers = null;
      this.contractors = null;
      this.start_date = null;
      this.end_date = null;
      this.status = null;
      this.getRequests();
    },
    changePage(e) {
      this.page = e;
      this.getRequests();
    },
    changeSort(event) {
      this.ordering = [];
      event.forEach((column) => {
        if (column.type.toUpperCase() !== "NONE") {
          this.ordering.push({
            orderColumn: column.field,
            orderBy: column.type.toUpperCase(),
          });
        }
      });
      this.getRequests();
    },
    /**
     * Обработчик для отоброжения сканера
     */
    showScanner() {
      this.isScannerQR = !this.isScannerQR;
    },
  },
  components: {
    LoadingIndicator,
    EyeOnIcon,
    EditIcon,
    Table,
    RequestsListActions,
    ScannerQR,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/card.styl"
@import "~@/styles/parts/request.styl"
</style>
